export const getEventsMonth = (date: string): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthIdx = new Date(date).getUTCMonth();
  return months[monthIdx];
};

export const getEventDay = (date: string): string => {
  const day = new Date(date).getUTCDate().toString();
  const dayToCompare = day.slice(-1);

  if (day == '11') {
    return `${day}th`;
  } else if (day == '12') {
    return `${day}th`;
  } else if (day == '13') {
    return `${day}th`;
  } else if (dayToCompare == '1') {
    return `${day}st`;
  } else if (dayToCompare == '2') {
    return `${day}nd`;
  } else if (dayToCompare == '3') {
    return `${day}rd`;
  } else {
    return `${day}th`;
  }
};

export const eventEnded = (eventEndDate: string): boolean => {
  const today = new Date();
  const endDate = new Date(eventEndDate);

  if (today.getUTCFullYear() < endDate.getUTCFullYear()) {
    return false;
  }

  if (today.getUTCFullYear() > endDate.getUTCFullYear()) {
    return true;
  }

  if (today.getUTCMonth() == endDate.getUTCMonth()) {
    return today.getUTCDate() > endDate.getUTCDate();
  } else if (today.getUTCMonth() < endDate.getUTCMonth()) {
    return false;
  } else {
    return true;
  }
};

export const hasEvent = (
  eventStartDate: string,
  eventEndDate: string,
): boolean => {
  const today = new Date();
  const startDate = new Date(eventStartDate);
  const endDate = new Date(eventEndDate);

  if (
    today.getUTCMonth() == startDate.getUTCMonth() &&
    today.getUTCMonth() == endDate.getUTCMonth()
  ) {
    //event starts and ends at the same month
    return (
      today.getUTCDate() >= startDate.getUTCDate() &&
      today.getUTCDate() <= endDate.getUTCDate()
    );
  } else if (
    today.getUTCMonth() > startDate.getUTCMonth() &&
    today.getUTCMonth() == endDate.getUTCMonth()
  ) {
    //event ends this month, we need to validate if the event is still occuring
    return today.getUTCDate() <= endDate.getUTCDate();
  } else {
    return false;
  }
};
